import moment from 'moment'
class GantPlus {
  constructor(config) {
    //列表数据
    this.list = []
    //列表数据中最大日期
    this.max = null
    //列表数据中最小日期
    this.min = null
    //甘特图宽度
    this.width = 0
    //甘特图配置
    this.config = {
      dayWidth: config.gantDayWidth || 10,
      list: config.list || [
        {
          start: "planStartDate",
          end: "planEndDate",
          color: "#007ff4",
          height: "20px",
        },
        {
          start: "actualStartDate", //开始字段key
          end: "actualEndDate", //结束字段key
          color: "#ffb822", //颜色
          height: "10px",
        },
      ],
    }
    // 甘特图表头
    this.headList = [],
    this.curDate = moment(new Date()).format('YYYY-MM-DD')
  }
  /**
   * 私有
   * @param {Array} list 原始数据列表
   * @param {Array} sortList  整理保存列表
   */
  #sort(list, sortList) {
    const configList = this.config.list
    list.forEach((row) => {
      for (let i = 0; i < configList.length; i++) {
        const item = configList[i]
        if(row[item.start]){
          const start = row[item.start]
          const start_date = new Date(start).getTime()
          sortList.push({
            time: start_date,
            str: start,
          })
        }
        if(row[item.end]){
          const end = row[item.end]
          const end_date = new Date(end).getTime()
          sortList.push({
            time: end_date,
            str: end,
          })
        }
      }
      if (row.children) {
        this.#sort(row.children, sortList)
      }
    })
    return sortList
  }
  /**
   * 整理出数据中最大、最小数据
   */
  sortData() {
    let sortList = []
    this.#sort(this.list, sortList)
    //对整理后的数据进行排序，栈顶为最大，栈低为最小
    sortList = sortList.sort((a, b) => b.time - a.time)
    this.max = sortList[0].time > new Date(this.curDate).getTime() ? sortList[0].str : this.curDate
    this.min = sortList[sortList.length - 1].str
  }
  /**
   * 私有
   * @param {Array} list 插入的列表 //必填
   * @param {*} parent 父节点 //必填
   * @param {*} data 插入数据 //必填
   */
  #appendData(list, parent, data) {
    for (let i = 0; i < list.length; i++) {
      const row = list[i]
      if (row.id == parent.id) {
        row.children = data
        break
      }
      if (row.children && row.children.length) {
        this.#appendData(row.children, parent, data)
      }
    }
  }
  /**
   * 设置列表数据
   * @param {Array} data 列表数据 //必填
   * @param {Object} parent 把数据插入该节点下的children，如果不传则直接替换list //非必填
   * @returns None
   */
  setData(data, parent) {
    if (!parent) {
      this.list = data || []
    } else {
      this.#appendData(this.list, parent, data)
    }
    this.sortData()
    this.sortHead()
  }
  #createMonthData(year, month) {
    //获取当前月份最大天数，即判断当月是29天、30天、31天。
    const day = new Date(year, month, 0).getDate()
    //顶部标题使用的格式
    const days = [
      {
        value: "1-15",
        des: [],
      },
      {
        value: `16-${day}`,
        des: [],
      },
    ]
    //记录每一天的时间戳，在row里定位要用到。
    for (let i = 1; i < 16; i++) {
      const des = `${year}-${month}-${i} 00:00:00`
      days[0].des.push(new Date(des).getTime())
    }
    for (let i = 16; i <= day; i++) {
      const des = `${year}-${month}-${i} 00:00:00`
      days[1].des.push(new Date(des).getTime())
    }
    return {
      title: `${year}年${month}月`,
      days,
      maxDay: day,
    }
  }
  /**
   * 整理甘特图表头
   */
  sortHead() {
    const date = new Date(this.min)
    // 获取最小年月
    let minYear = date.getFullYear()
    let minMonth = date.getMonth() + 1
    let minDate = new Date(`${minYear}-${minMonth}-1`).getTime()
    let maxDate = new Date(this.max).getTime()
    const headList = []
    let width = 0
    while (minDate <= maxDate) {
      const days = this.#createMonthData(minYear, minMonth)
      headList.push(days)
      width += days.maxDay
      //切换到下一月，进行下一个循环。
      minMonth += 1
      //如果超过12月，转到第二年
      if (minMonth > 12) {
        minMonth = 1
        minYear += 1
      }
      minDate = minDate = new Date(`${minYear}-${minMonth}-1`).getTime()
    }
    this.width = width * this.config.dayWidth
    this.headList = headList
  }
}

export default GantPlus
